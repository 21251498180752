import React, { useEffect } from 'react';
import { Modal } from 'antd';

export const useClearLocalStorageOnInactivity = () => {
  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (localStorage.length !== 0) {
          localStorage.removeItem("adminToken")
          console.log("Kullanıcı hareketsizlik nedeniyle yerel depo temizlendi.");
          Modal.warning({
            title: 'Oturum süreniz sona erdi',
            content: 'Hareketsizlik nedeniyle oturumunuz sona erdi. Lütfen tekrar oturum açın.',
            okText: "Tamam",
            onOk: () => window.location.reload()
          });
        }
      }, 120000);
    };

    window.onload = resetTimer;
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keypress', resetTimer);

    return () => {
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keypress', resetTimer);
    };
  }, []);
};