import './App.css';
import routes from './src/Router/Route';
import { RouterProvider } from 'react-router-dom';
import Navbar from './src/components/Navbar/Navbar';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <RouterProvider router={routes}/>
    </div>
  );
}

export default App;