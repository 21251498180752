import axios from 'axios';


export const AddCourseApi = ({id,course}) => {

    const form = {
        id:id,
        kurs_adi:course
    }

  return axios.post('https://milensoft.com/api/trf/addChallange.php',form,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
