import React, { useState, useEffect } from "react";
import logo from "../../assets/image/logo.svg";
import "../../assets/style/admin/Admin.css";
import HakemEkle from "./partials/HakemEkle";
import RefereeEdit from "./partials/RefereeEdit";
import AddCourse from "./partials/AddCourse";
import BekleyenIslemler from "./partials/BekleyenIslemler";
import { useClearLocalStorageOnInactivity } from "../../hooks/useClearlocal";
import Dilekce from "./partials/Dilekce";

const AdminPanel = () => {
  const token = localStorage.getItem("adminToken");
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState("home");
  const [isLoading, setIsLoading] = useState(true);

  useClearLocalStorageOnInactivity()

  useEffect(() => {
    if (!token) {
      window.location.href = "/admin-login";
    } else {
      setIsLoading(false);
    }
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "");
      setCurrentPage(hash || "home");
    };
  
    const updateSidebarVisibility = () => {
      setIsOpen(window.innerWidth >= 768);
    };
  
    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("resize", updateSidebarVisibility);
    updateSidebarVisibility();
    handleHashChange();
  
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("resize", updateSidebarVisibility);
    };
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading...</div>; 
    }

    switch (currentPage) {
      case "home":
        return <HakemEkle/>;
      case "hakeme-gorev-atama":
        return <AddCourse/>;
      case "hakem-edit":
        return <RefereeEdit/>
      case "bekleyen-islemler":
          return <BekleyenIslemler/>
      case "bildirgeler":
          return <Dilekce/>
      default:
        return null;
    }
  };


  const logout = () => {
    localStorage.removeItem("adminToken")
     window.location.reload()
  }
  return (
    <div className="app">
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img style={{ width: "50%", margin: "20px 0" }} src={logo} alt="Logo" />
        </div>

        <ul className="side-list">
          <li>
            <a className={currentPage === "home" ? "selected-link" : ""} href="#home">
              <i className="fa fa-home" aria-hidden="true"></i> <p>Hakem Ekleme</p>
            </a>
          </li>
          <li>
            <a className={currentPage === "hakeme-gorev-atama" ? "selected-link" : ""} href="#hakeme-gorev-atama">
            <i className="fa-solid fa-list" aria-hidden="true"></i><p>Hakeme Görev Atama</p>
            </a>
          </li>
          <li>
            <a className={currentPage === "hakem-edit" ? "selected-link" : ""} href="#hakem-edit">
            <i className="fa-solid fa-pen-to-square" aria-hidden="true"></i><p>⁠⁠Hakem Silme/Düzenleme</p>
            </a>
          </li>
          <li>
            <a className={currentPage === "bekleyen-islemler" ? "selected-link" : ""} href="#bekleyen-islemler">
            <i className="fa-solid fa-hourglass-start" aria-hidden="true"></i><p>⁠Bekleyen işlemler</p>
            </a>
          </li>
          <li>
            <a className={currentPage === "bildirgeler" ? "selected-link" : ""} href="#bildirgeler">
            <i className="fa-solid fa-paperclip" aria-hidden="true"></i><p>Değişim Bildirgesi</p>
            </a>
          </li>
          <li className="d-flex align-items-center" style={{marginTop:"1rem",cursor:"pointer"}} onClick={logout}>
            <i className="fa-solid fa-right-from-bracket" aria-hidden="true"></i><p style={{marginLeft:"3px"}}>Çıkış Yap</p>
          </li>
        </ul>
      </div>

      <div className="content">
        <div style={{ width: "100%" }}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default AdminPanel;
