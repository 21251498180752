export const columns = [
  {
    title: "T.C. Kimlik No",
    dataIndex: "tc_kimlik_no",
    key: "tc_kimlik_no",
  },
  {
    title: "Ad Soyad",
    dataIndex: "ad_soyad",
    key: "ad_soyad",
  },

  {
    title: "Telefon No",
    dataIndex: "tel_no",
    key: "tel_no",
  },
  {
    title: "Belge Numarası",
    dataIndex: "belge_no",
    key: "belge_no",
  },
  {
    title: "Eposta",
    dataIndex: "email",
    key: "belge_no",
  },
  {
    title: 'Detaylar',
    key: 'x',
    render: () => <a className="text-success font-weight-bold">İncele</a>, 
  },
];
