import React, { useState } from "react";
import { Modal, message, Input, Button, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";

export default function UploadModal({ modal, close }) {
  const [showText, setShowText] = useState(false);
  const [file, setFile] = useState(null);
  const [tcNo, setTcNo] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [submitting, setSubmitting] = useState(false); 

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleSend = async () => {
    try {
      const formData = new FormData();
      formData.append("dilekce", file);
      formData.append("tc", tcNo);

      if (!file || !tcNo) {
        alert("Lütfen tüm zorunlu alanları doldurun.");
        return;
      }

      setSubmitting(true); 

      const response = await axios.post(
        "https://milensoft.com/api/trf/uploadPetition.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        message.success("Dosya başarıyla yüklendi.");
        setFile(null)
        setTcNo("")
        close();
      } else {
        throw new Error("Dosya yüklenirken bir hata oluştu.");
      }
    } catch (error) {
      message.error("Dosya yüklenirken bir hata oluştu.");
    } finally {
      setSubmitting(false); 
    }
  };

  return (
    <Modal
      title="Bekleyen Hakem"
      visible={modal}
      onOk={close}
      onCancel={close}
      footer={null}
    >
      <div>
        <div className="upload-file-btns d-flex">
          <button className="bildirge-btn">
            <a href="http://milensoft.com/api/trf/demo.docx" download>
            <i className="fa-solid fa-download"></i> Dilekçeyi İndir
            </a>
          </button>
          <button
            style={{ backgroundColor: "green", color: "white" }}
            className="bildirge-btn"
            onClick={() => {
              setShowText(!showText);
            }}
          >
            Dilekçeyi Yükle
          </button>
        </div>
        {showText ? (
          <>
            <Input
              placeholder="T.C. Kimlik Numarası"
              value={tcNo}
              onChange={(e) => setTcNo(e.target.value)}
              style={{ margin: "1rem 0rem" }}
            />
            <div
              className={`drag-drop-input ${
                isDragging ? "drag-over" : ""
              }`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <i className="fa-solid fa-download fa-2x" aria-hidden="true"></i>
              <p>
                Dosyayı buraya sürükleyin veya{" "}
                <label htmlFor="fileInput" className="file-input-label">
                  tıklayın
                </label>{" "}
                ve seçin
              </p>
              <input
                id="fileInput"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
            {file && (
              <p>
                <i class="fa-regular fa-file"></i> Seçilen dosya : {file.name}
              </p>
            )}
            <button
              className="bildirge-btn"
              style={{ width: "100%", marginTop: "1rem" }}
              onClick={handleSend}
              disabled={submitting} // submitting state'ine göre butonu devre dışı bırakın
            >
              {submitting ? (
                <Spin /> // submitting durumunda Spin bileşenini gösterin
              ) : (
                "Gönder"
              )}
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}
