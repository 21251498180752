import axios from 'axios';


export const DeleteReferee = (id) => {
    const form = {
        id:id
    }

  return axios.post('https://milensoft.com/api/trf/deleteReferee.php',form,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
