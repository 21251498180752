import React, { useState } from 'react';
import "../../assets/style/register/Register.css"
import axios from 'axios';
import cityData from "../../data/cityData.json"

const Register = () => {
  const [formData, setFormData] = useState({
    ad_soyad: '',
    cinsiyet: 'Erkek',
    il: 'Adana',
    tel_no: '',
    tc_kimlik_no: '',
    email: '',
    iban: '',
    kimlik_karti: null,
    brans_detay: 'Ragbi',
    kan_grubu: 'A+',
    belge_no:'',
    hakem_derecesi: '',
    mezuniyet: '',
    kurum_alani: ''
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const val = type === 'file' ? e.target.files[0] : value;

    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://milensoft.com/api/trf/register.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response);
      // alert("Ön Başvuru Kaydınız Alınmıştır")
      // window.location.href = "/"
    } catch (error) {
      console.error('Error occurred while submitting form:', error);
    }
  };

  return (
    <div className='register-form-section'>
    <form onSubmit={handleSubmit}>
      <label>
        Ad Soyad:
        <input required type="text" name="ad_soyad" value={formData.ad_soyad} onChange={handleChange} />
      </label>
      <label>
        Cinsiyet:
        <select required name="cinsiyet" value={formData.cinsiyet} onChange={handleChange}>
          <option value="Erkek">Erkek</option>
          <option value="Kadın">Kadın</option>
        </select>
      </label>
      <label>
    İl:
    <select required name="il" value={formData.il} onChange={handleChange}>
        {cityData.map((item, index) => (
            <option key={index} value={item.il}>{item.il}</option>
        ))}
    </select>
</label>

      <label>
        Telefon Numarası:
        <input required type="text" name="tel_no" value={formData.tel_no} onChange={handleChange} />
      </label>
      <label>
        TC Kimlik Numarası:
        <input required type="text" name="tc_kimlik_no" value={formData.tc_kimlik_no} onChange={handleChange} />
      </label>
      <label>
        E-posta:
        <input required type="email" name="email" value={formData.email} onChange={handleChange} />
      </label>
      <label>
        IBAN Numarası:
        <input required type="text" name="iban" value={formData.iban} onChange={handleChange} />
      </label>
      <label>
        Kimlik Kartı:
        <input required type="file" name="kimlik_karti" onChange={handleChange} />
      </label>
      <label>
        Belge Numarası:
        <input required type="text" name="belge_no" value={formData.belge_no} onChange={handleChange} />
      </label>
      <label>
        Branş Detayı:
        <select required name="brans_detay" value={formData.brans_detay} onChange={handleChange}>
          <option value="Ragbi">Ragbi</option>
          <option value="Beyzbol">Beyzbol</option>
          <option value="Softbol">Softbol</option>
          <option value="Korumalı Futbol">Korumalı Futbol</option>
        </select>
      </label>
      <label>
        Kan Grubu:
        <select required name="kan_grubu" value={formData.kan_grubu} onChange={handleChange}>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
          <option value="0+">0+</option>
          <option value="0-">0-</option>
        </select>
      </label>
      <label>
        Hakem Derecesi:
        <input required type="text" name="hakem_derecesi" value={formData.hakem_derecesi} onChange={handleChange} />
      </label>
      <label>
        Mezuniyet:
        <input required type="text" name="mezuniyet" value={formData.mezuniyet} onChange={handleChange} />
      </label>
      <label>
        Kurum Alanı:
        <input required type="text" name="kurum_alani" value={formData.kurum_alani} onChange={handleChange} />
      </label>
      <button type="submit">Kaydol</button>
    </form>
    </div>

  );
};

export default Register;
