import axios from 'axios';


export const SearchReferee = (tc) => {

  const form = {
    tc_kimlik_no:tc
  }

  return axios.post('https://milensoft.com/api/trf/searchReferee.php',form,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
