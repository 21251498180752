import { Modal } from "antd";
import React from "react";

export default function DetailModal({ modal, close, data }) {
  console.log(data);
  return (
    <Modal
      title="Hakem Detayları"
      visible={modal}
      onOk={close}
      onCancel={close}
      footer={null}
    >
      <p className="home-subtitile">Kişisel Bilgiler</p>
      <div className="info-container">
        <div className="info-card">
          <p>Ad Soyad</p>
          <div className="card-text">
            <p className="info-text">{data?.ad_soyad}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Cinsiyet</p>
          <div className="card-text">
            <p className="info-text">{data?.cinsiyet}</p>
          </div>
        </div>
        <div className="info-card">
          <p>T.C Kimlik Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.tc_kimlik_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>İl</p>
          <div className="card-text">
            <p className="info-text">{data?.il}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Telefon Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.tel_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>E-Posta</p>
          <div className="card-text">
            <p className="info-text">{data?.email}</p>
          </div>
        </div>
        <div style={{ width: "100%" }} className="info-card">
          <p>Iban Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.iban}</p>
          </div>
        </div>
      </div>
      <p className="home-subtitile">Aldığı Kurslar</p>
      <div className="info-container" style={{width:"100%"}}>
        {data?.kurs_adi?.kurslar.map((item, i) => (
          <div className="info-card">
            <p>Kurs Adı</p>
            <div className="card-text" key={i}>
              <p className="info-text">{item}</p>
            </div>
          </div>
        ))}
            </div>
       
      <p className="home-subtitile">Kurs & Aday Bilgileri</p>

      <div className="info-container">


        <div className="info-card">
          <p>Aday Hakem Belge Tarihi</p>
          <div className="card-text">
            <p className="info-text">{data?.aday_hakem_belge_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Hakem Kurs Tarihi</p>
          <div className="card-text">
            <p className="info-text">{data?.il_hakemi_kurs_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>İl Hakemi Belge Tarihi</p>
          <div className="card-text">
            <p className="info-text">{data?.il_hakemi_belge_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Belge NO</p>
          <div className="card-text">
            <p className="info-text">{data?.belge_no}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
