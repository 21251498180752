import React, { useState } from 'react';
import "../../assets/style/admin/AdminLogin.css"
import logo from "../../assets/image/logo.svg"
import { AdminLoginApi } from '../../api/AdminLogin';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd'; 


export default function AdminLogin() {
  
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState(""); 
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
  
    const handleLogin = (e) => {
      e.preventDefault();
      AdminLoginApi({userName: username, password: password})
      .then((response) => {
        if(response?.data[0]?.token){
          localStorage.setItem("adminToken",response?.data[0]?.token)
          navigate('/admin', { state: { admin: response.data[0] } });
        }else{
          setErrorMessage("Giriş Bilgileri Yanlış");
        }
      })    
    };
  
    return (
      <div className="admin-login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <div className='admin-image'>
          <img alt='logo' src={logo}/>
          </div>
          <h2 className="form-title">Yönetici Girişi</h2>
          {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
          <div className="input-group">
            <label htmlFor="username">Kullanıcı Adı</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Şifre</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button style={{width:"100%"}} type="submit" className="button">Giriş Yap</button>
        </form>
      </div>
    );
  }