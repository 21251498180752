import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import PendinModal from "../../../components/pendingModal";
import { BildirgeApi } from "../../../api/BildirgeApi";

export default function Dilekce() {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);

  useEffect(() => {
    BildirgeApi().then((response) => {
      console.log(response);
      const reversedData = response?.data?.reverse();
      setOriginalData(reversedData);
      setFilteredData(reversedData);
    });
  }, [detailOpen]);

  useEffect(() => {
    const filtered = originalData.filter(item =>
      item?.tc?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, originalData]);

  console.log(filteredData);

  const columns = [
    {
      title: "T.C. Kimlik No",
      dataIndex: "tc",
      key: "tc",
    },
    {
      title: "Değişim Bildirgesi",
      dataIndex: "dilekce",
      key: "dilekce",
      render: (text, record) => (
        <a href={`https://milensoft.com/api/trf/${record.dilekce}`} onClick={() => handleLinkClick(record)}>incele</a>
      ),
    },
  ];

  const handleLinkClick = (record) => {
    // Burada tıklanan öğeye göre işlemler yapabilirsiniz
    console.log("Tıklanan veri:", record.dilekce);
    // Açılacak olan a etiketinin işlevselliğini burada belirleyebilirsiniz
  };

  return (
    <div className="admin-home-container">
      <div
        className="admin-cards"
        style={{ justifyContent: "space-between", marginBottom: "2rem" }}
      >
        <div style={{ backgroundColor: "#037D00" }} className="admin-card">
          <i className="fa-solid fa-users fa-3x"></i>
          <div className="admin-card-text">
            <p className="count">
              <strong>{originalData?.length}</strong>
            </p>
            <p>Toplam Hakem Sayısı</p>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Hakem Adına Göre Ara"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={{ showSizeChanger: false, position: ["bottomCenter"] }}
      />
    </div>
  );
}
