import axios from 'axios';


export const PendingProceses = () => {

  return axios.post('https://milensoft.com/api/trf/reviewList.php',{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
