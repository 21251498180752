import { createBrowserRouter } from "react-router-dom";
import Login from "../screens/login";
import Home from "../screens/home";
import AdminPanel from "../screens/admin";
import AdminLogin from "../screens/admin/AdminLogin";
import Register from "../screens/register";
import FileDownloader from "../deneme";


const routes = createBrowserRouter([
    {
        path:"/",
        element:<Login/>
    },
    {
        path:"/kayit-ol",
        element:<Register/>
    },
    {
        path:"/sonuc",
        element:<Home/>
    },
    {
        path:"/admin",
        element:<AdminPanel/>
    },
    {
        path:"/admin-login",
        element:<AdminLogin/>
    },
    {
        path:"/deneme",
        element:<FileDownloader/>
    }

    

])

export default routes
