import React,{useState} from 'react'
import { Modal } from 'antd'
import { AddCourseApi } from '../../api/AddCourseApi';

export default function AddCourseModal({modal,close,data}) {
    const [course, setCourse] = useState("")
    
    const submit = (event) => {
        event.preventDefault();
        AddCourseApi({id: data.id, course: course}).then((response)=>{
            alert(response.data.message)
            setCourse("")
            close()
        })
    }

    return (
        <Modal
        title="Kurs Ekle"
        visible={modal}
        onOk={close}
        onCancel={close}
        footer={null}
        >
            <form onSubmit={submit}>
                <div className='auditor-form-group'>
                    <label>Kurs Adı : </label>
                    <input placeholder='Lütfen Kurs Adını Giriniz' required onChange={(e)=>setCourse(e.target.value)} />
                    <button style={{marginTop:"1rem"}} type='submit'>Kaydet</button>
                </div>
            </form>
        </Modal>
    )
}
