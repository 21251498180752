import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { columns } from '../../../data/PendingColımns';
import { PendingProceses } from "../../../api/PendingProceses";
import PendinModal from "../../../components/pendingModal";


export default function BekleyenIslemler() {
    const [originalData, setOriginalData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]); 
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRowData, setSelectedRowData] = useState(null);
    // const [addReferee, setAddReferee] = useState(false)
    const [detailOpen,setDetailOpen] = useState(false)

    useEffect(() => {
      PendingProceses().then((response) => {
        console.log(response);
          const reversedData = response?.data.slice().reverse(); 
            setOriginalData(reversedData); 
            setFilteredData(reversedData); 
        });
      }, [detailOpen]);


  useEffect(() => {
    const filtered = originalData.filter(item =>
      item?.ad_soyad?.toLowerCase().includes(searchTerm?.toLowerCase()) 
    );
    setFilteredData(filtered);
  }, [searchTerm, originalData]);

  
  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setDetailOpen(true)
  };


  
  return (
    <div className="admin-home-container">
      <div
        className="admin-cards"
        style={{ justifyContent: "space-between", marginBottom: "2rem" }}
      >
        <div style={{ backgroundColor: "#037D00" }} className="admin-card">
          <i className="fa-solid fa-users fa-3x"></i>
          <div className="admin-card-text">
            <p className="count">
              <strong>{originalData?.length}</strong>
            </p>
            <p>Toplam Hakem Sayısı</p>
          </div>
        </div>

      </div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Hakem Adına Göre Ara"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredData} 
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record), 
          };
        }}
        pagination={{ showSizeChanger: false, position: ["bottomCenter"] }}
      />
      <PendinModal modal={detailOpen} close={()=>setDetailOpen(false)} data={selectedRowData}/>
    </div>
  );
}
