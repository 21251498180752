import axios from 'axios';

export const UpdateReferee = (formData) => {

  return axios.post('https://milensoft.com/api/trf/updateReferee.php',formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
