import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import "../../assets/style/Login/Login.css";
import { SearchReferee } from '../../api/SearchReferee';
import { Alert,Button,Modal} from 'antd'; 
import UploadModal from '../../components/uploadDilekceModal';

export default function Login() {
  const [modal, setmodal] = useState(false)
  const [modalUpload, setmodalUpload] = useState(false)
  const [tc, setTc] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 
  const navigate = useNavigate();

  const submit = () => {
    setLoading(true);
    setErrorMessage(""); 
    SearchReferee(tc).then((ress) => {
      if (ress?.data[0]) {
        navigate('/sonuc', { state: { data: ress.data[0] } });
      } else {
        setmodal(true)
      }
    }).catch(error => {
      console.error("API call failed:", error);
      setErrorMessage("Bir hata oluştu, lütfen tekrar deneyin."); 
    }).finally(() => {
      setLoading(false); 
    });
  };


  const handeleCloseModal = () => {setmodal(false)}

  return (
    <div className='login-container'>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        <div className='login-section'>
            <p>TC Kimlik Numarası</p>
            <input placeholder='TC kimlik numarasınız girin' required type='number' onChange={(e) => setTc(e.target.value)} />
            <button onClick={submit} className='button' disabled={loading}>{loading ? 'Yükleniyor...' : 'Sorgula'}</button>
            <button className='button' onClick={()=>{setmodalUpload(true)}}> Değişke Bildirgesi Yükle</button>
        </div>
        <Modal 
        title="Uyarı"
        visible={modal}
        onOk={handeleCloseModal}
        onCancel={handeleCloseModal}
        footer={null}
        >
      <p>T.C Kimlik Numarası Hatalı !</p>
      <div className='d-flex justify-content-end'>
      <Button style={{margin:"0rem 1rem"}} danger onClick={()=> window.location.href = "/kayit-ol"}>Kayıt Ol</Button>
      <Button onClick={handeleCloseModal}>Tekrar Dene</Button>
      </div>

      </Modal>
      <UploadModal modal={modalUpload} close={()=>setmodalUpload(false)}/>
    </div>


  );
}
