import axios from 'axios';


export const AllReferee = () => {

  return axios.post('https://milensoft.com/api/trf/allReferee.php',{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
