import React, { useState, useEffect } from 'react';
import { UpdateReferee } from '../../api/UpdateReferee';

export default function Edit({ data, close }) {
console.log(data);
  const [formData, setFormData] = useState({
    ad_soyad: data.ad_soyad,
    aday_hakem_belge_tarihi: data.aday_hakem_belge_tarihi,
    belge_no: data.belge_no,
    cinsiyet: data.cinsiyet,
    email: data.email,
    // iban: data.iban,
    id: data.id,
    il: data.il,
    il_hakemi_belge_tarihi: data.il_hakemi_belge_tarihi,
    il_hakemi_kurs_tarihi: data.il_hakemi_kurs_tarihi,
    tc_kimlik_no: data.tc_kimlik_no,
    tel_no: data.tel_no,
  });

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    UpdateReferee(formData).then(response => {
      if (response.data.message === "Güncelleme işlemi başarılı") {
        alert(response.data.message);
        close();
      } else {
        alert("Bir Problem Oluştu");
      }
    });
  };

  return (
    <div className='edit-modal-container' style={{ textAlign: "start" }}>
      <form onSubmit={handleSubmit}>
        <div className="auditor-form-group">
          <label htmlFor="ad_soyad">Ad Soyad:</label>
          <input
            id="ad_soyad"
            name="ad_soyad"
            type="text"
            value={formData.ad_soyad}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="aday_hakem_belge_tarihi">Aday Hakem Belge Tarihi:</label>
          <input
            id="aday_hakem_belge_tarihi"
            name="aday_hakem_belge_tarihi"
            type="date"
            value={formData.aday_hakem_belge_tarihi}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="belge_no">Belge No:</label>
          <input
            id="belge_no"
            name="belge_no"
            type="text"
            value={formData.belge_no}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="cinsiyet">Cinsiyet:</label>
          <select
            id="cinsiyet"
            name="cinsiyet"
            value={formData.cinsiyet}
            onChange={handleChange}
          >
            <option value="">Seçiniz</option>
            <option value="Erkek">Erkek</option>
            <option value="Kadın">Kadın</option>
          </select>
        </div>
        <div className="auditor-form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        {/* <div className="auditor-form-group">
          <label htmlFor="iban">IBAN:</label>
          <input
            id="iban"
            name="iban"
            type="text"
            value={formData.iban}
            onChange={handleChange}
          />
        </div> */}
        <div className="auditor-form-group">
          <label htmlFor="il">İl:</label>
          <input
            id="il"
            name="il"
            type="text"
            value={formData.il}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="il_hakemi_belge_tarihi">İl Hakemi Belge Tarihi:</label>
          <input
            id="il_hakemi_belge_tarihi"
            name="il_hakemi_belge_tarihi"
            type="date"
            value={formData.il_hakemi_belge_tarihi}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="il_hakemi_kurs_tarihi">İl Hakemi Kurs Tarihi:</label>
          <input
            id="il_hakemi_kurs_tarihi"
            name="il_hakemi_kurs_tarihi"
            type="date"
            value={formData.il_hakemi_kurs_tarihi}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="tc_kimlik_no">TC Kimlik No:</label>
          <input
            id="tc_kimlik_no"
            name="tc_kimlik_no"
            type="text"
            value={formData.tc_kimlik_no}
            onChange={handleChange}
          />
        </div>
        <div className="auditor-form-group">
          <label htmlFor="tel_no">Telefon No:</label>
          <input
            id="tel_no"
            name="tel_no"
            type="text"
            value={formData.tel_no}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Kaydet</button>
      </form>
    </div>
  );
}
