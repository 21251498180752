import { Button, Modal } from "antd";
import axios from "axios";
import React from "react";


export default function PendinModal({ modal, close, data }) {
  const url = `https://milensoft.com/api/trf/identity/${data?.kimlik_karti}`;


  const form ={
    id:data?.id,
    on_basvuru:"1"
  }

  const handleApprove = () => {
    axios.post("https://milensoft.com/api/trf/approvalReferee.php",form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((ress)=>{
      alert(ress?.data);
      close()
    })
  }
  console.log(data);
  return (
    <Modal
      title="Bekleyen Hakem"
      visible={modal}
      onOk={close}
      onCancel={close}
      footer={null}
    >
      <p className="home-subtitile">Kişisel Bilgiler</p>
      <div className="info-container">
        <div className="info-card">
          <p>Ad Soyad</p>
          <div className="card-text">
            <p className="info-text">{data?.ad_soyad}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Cinsiyet</p>
          <div className="card-text">
            <p className="info-text">{data?.cinsiyet}</p>
          </div>
        </div>
        <div className="info-card">
          <p>T.C Kimlik Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.tc_kimlik_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>İl</p>
          <div className="card-text">
            <p className="info-text">{data?.il}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Telefon Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.tel_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>E-Posta</p>
          <div className="card-text">
            <p className="info-text">{data?.email}</p>
          </div>
        </div>
        <div style={{ width: "100%" }} className="info-card">
          <p>Iban Numarası</p>
          <div className="card-text">
            <p className="info-text">{data?.iban}</p>
          </div>
        </div>
      </div>

      <div className="info-container">
        <div className="info-card">
          <p>kan Grubu</p>
          <div className="card-text">
            <p className="info-text">{data?.kan_grubu}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Kurum Alanı</p>
          <div className="card-text">
            <p className="info-text">{data?.kurum_alani}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Hakem Derecesi</p>
          <div className="card-text">
            <p className="info-text">{data?.hakem_derecesi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Mezuniyet</p>
          <div className="card-text">
            <p className="info-text">{data?.mezuniyet}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Belge NO</p>
          <div className="card-text">
            <p className="info-text">{data?.belge_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Kimlik Fotoğrafı</p>
          <div className="card-text">
            <p
              style={{ cursor: "pointer" }}
              className="info-text text-primary"
              onClick={() => window.open(url, "_blank")}
            >
              Fotoğrafı Gör
            </p>
          </div>
        </div>
        <button style={{backgroundColor:"green",marginTop:"1rem"}} onClick={handleApprove} className="login-button"> Onayla</button>
      </div>
    </Modal>
  );
}
