// https://milensoft.com/api/trf/login.php
import axios from 'axios';


export const AdminLoginApi = ({userName,password}) => {

    const form = {
        username:userName,
        password:password
    }

  return axios.post('https://milensoft.com/api/trf/login.php',form,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
