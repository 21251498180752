import React, { useState, useEffect } from 'react';
import logo from "../../assets/image/logo.svg";
import "../../assets/style/Navbar/Navbar.css";

export default function Navbar() {
  const path = window.location.pathname;
  const [paths, setPaths] = useState(false);

  useEffect(() => {
    if (path === "/sonuc") {
      setPaths(true);
    }
  }, [paths]);

  const hideNavbar = path === "/admin" || path === "/admin-login";


  return (
    <div style={{
      background: paths ? "#FFF" : "",
      display: hideNavbar ? "none" : ""
    }} className='navbar-container'>
        <img className='logo' alt='logo' src={logo} />
        <div className="navbar-titles">
            <p style={{color:paths ? "#959595" : ""}} className='title'>TÜRKİYE RAGBİ FEDERASYONU </p>
            <p style={{color:paths ? "#959595" : ""}} className='subtitle'>HAKEM SORGULAMA SİSTEMİ</p>
        </div>
    </div>
  );
}
 