import React from 'react';

function FileDownloader() {
  return (
    <div>
        <a href='https://calibre-ebook.com/downloads/demos/demo.docx' download>yükle</a>
    </div>
  );
}

export default FileDownloader;
