import { Modal } from "antd";
import React, { useState } from "react";
import "./AddRefereeModal.css";
import axios from "axios";
import cityData from "../../data/cityData.json";

export default function AddRefereeModal({ modal, close }) {
  const [adSoyad, setAdSoyad] = useState("");
  const [cinsiyet, setCinsiyet] = useState("");
  const [tcKimlikNo, setTcKimlikNo] = useState("");
  const [il, setIl] = useState("");
  const [telNo, setTelNo] = useState("");
  const [email, setEmail] = useState("");
  const [kursAdi, setKursAdi] = useState("");
  const [adayHakemBelgeTarihi, setAdayHakemBelgeTarihi] = useState("");
  const [ilHakemiKursTarihi, setIlHakemiKursTarihi] = useState("");
  const [ilHakemiBelgeTarihi, setIlHakemiBelgeTarihi] = useState("");
  const [belgeNo, setBelgeNo] = useState("");
  const [iban,setIban] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("ad_soyad", adSoyad);
    formData.append("cinsiyet", cinsiyet);
    formData.append("tc_kimlik_no", tcKimlikNo);
    formData.append("il", il);
    formData.append("tel_no", telNo);
    formData.append("email", email);
    formData.append("kurs_adi", kursAdi);
    formData.append("aday_hakem_belge_tarihi", adayHakemBelgeTarihi);
    formData.append("il_hakemi_kurs_tarihi", ilHakemiKursTarihi);
    formData.append("il_hakemi_belge_tarihi", ilHakemiBelgeTarihi);
    formData.append("belge_no", belgeNo);
    formData.append( "iban",iban)
 
    axios.post("https://milensoft.com/api/trf/addReferee.php",formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((ress)=>{close()})
  };

  return (
    <Modal
      title="Hakem EKle"
      visible={modal}
      onOk={close}
      onCancel={close}
      footer={null}
    >
      <>
        <form onSubmit={handleSubmit}>
          <div className="auditor-form-group">
            <label>Ad Soyad:</label>
            <input
            required
              type="text"
              value={adSoyad}
              onChange={(e) => setAdSoyad(e.target.value)}
              placeholder="Ad Soyad"
            />
          </div>
          <div className="auditor-form-group">
            <label>Cinsiyet</label>
            <select
            required
              value={cinsiyet}
              onChange={(e) => setCinsiyet(e.target.value)}
            >
              <option value="">Cinsiyet Seçin</option>
              <option value="Erkek">Erkek</option>
              <option value="Kadın">Kadın</option>
            </select>
          </div>
          <div className="auditor-form-group">
            <label>T.C Kimlik Numarası</label>
            <input
            required
              type="number"
              value={tcKimlikNo}
              onChange={(e) => setTcKimlikNo(e.target.value)}
              placeholder="TC Kimlik No"
            />
          </div>
          <div className="auditor-form-group">
  <label>Şehir Seçin</label>
  <select required value={il} onChange={(e) => setIl(e.target.value)}>
  <option value="">Şehir Seçin</option>
  {cityData.map((item) => (
    <option key={item.il} value={item.il}>
      {item.il}
    </option>
  ))}
</select>
</div>

          <div className="auditor-form-group">
            <label>Telefon Numarası</label>
            <input
            required
              type="text"
              value={telNo}
              onChange={(e) => setTelNo(e.target.value)}
              placeholder="Telefon No"
            />
          </div>
          <div className="auditor-form-group">
            <label>E-posta</label>
            <input
            required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div className="auditor-form-group">
            <label>Kurs Adı</label>
            <input
            required
              type="text"
              value={kursAdi}
              onChange={(e) => setKursAdi(e.target.value)}
              placeholder="Kurs Adı"
            />
          </div>
          <div className="auditor-form-group">
            <label>Aday Hakem Belge Tarihi</label>
            <input
            required
              type="date"
              value={adayHakemBelgeTarihi}
              onChange={(e) => setAdayHakemBelgeTarihi(e.target.value)}
              placeholder="Aday Hakem Belge Tarihi"
            />
          </div>

          <div className="auditor-form-group">
            <label>İl Hakemi Kurs Tarihi</label>
            <input
            required
              type="date"
              value={ilHakemiKursTarihi}
              onChange={(e) => setIlHakemiKursTarihi(e.target.value)}
              placeholder="İl Hakemi Kurs Tarihi"
            />
          </div>
          <div className="auditor-form-group">
            <label>İl Hakemi Belge Tarihi</label>
            <input
            required
              type="date"
              value={ilHakemiBelgeTarihi}
              onChange={(e) => setIlHakemiBelgeTarihi(e.target.value)}
              placeholder="İl Hakemi Belge Tarihi"
            />
          </div>
          <div className="auditor-form-group">
            <label>Belge Numarası</label>
            <input
            required
              type="text"
              value={belgeNo}
              onChange={(e) => setBelgeNo(e.target.value)}
              placeholder="Belge No"
            />
          </div>
          <div className="auditor-form-group">
            <label>IBAN Numarası:</label>
            <input
            required
              type="text"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              placeholder="iban"
            />
          </div>

          <button type="submit">Gönder</button>
        </form>
      </>
    </Modal>
  );
}
