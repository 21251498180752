import React,{useEffect,useState} from 'react'
import "../../assets/style/Home/Home.css"
import { useLocation } from 'react-router-dom';

export default function Home() {
  const location = useLocation();
  const data = location.state.data;
  const [Data, setData] = useState(data)

  useEffect(()=>{
    setData(data)
  },[data])

  const back = () => {
    window.location.href = "/"
  }

  return (
    <div className='home-container'>
     <p className="home-subtitile">Kişisel Bilgiler</p>
      <div className="info-container">
        <div className="info-card">
          <p>Ad Soyad</p>
          <div className="card-text">
            <p className="info-text">{Data?.ad_soyad}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Cinsiyet</p>
          <div className="card-text">
            <p className="info-text">{Data?.cinsiyet}</p>
          </div>
        </div>
        <div className="info-card">
          <p>T.C Kimlik Numarası</p>
          <div className="card-text">
            <p className="info-text">{Data?.tc_kimlik_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>İl</p>
          <div className="card-text">
            <p className="info-text">{Data?.il}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Telefon Numarası</p>
          <div className="card-text">
            <p className="info-text">{Data?.tel_no}</p>
          </div>
        </div>
        <div className="info-card">
          <p>E-Posta</p>
          <div className="card-text">
            <p className="info-text">{Data?.email}</p>
          </div>
        </div>
        <div style={{ width: "100%" }} className="info-card">
          <p>Iban Numarası</p>
          <div className="card-text">
            <p className="info-text">{Data?.iban}</p>
          </div>
        </div>
      </div>
      <p className="home-subtitile">Aldığı Kurslar</p>
      <div className="info-container" style={{width:"100%"}}>
        {Data?.kurs_adi?.kurslar.map((item, i) => (
          <div className="info-card">
            <p>Kurs Adı</p>
            <div className="card-text" key={i}>
              <p className="info-text">{item}</p>
            </div>
          </div>
        ))}
            </div>
       
      <p className="home-subtitile">Kurs & Aday Bilgileri</p>

      <div className="info-container">


        <div className="info-card">
          <p>Aday Hakem Belge Tarihi</p>
          <div className="card-text">
            <p className="info-text">{Data?.aday_hakem_belge_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Hakem Kurs Tarihi</p>
          <div className="card-text">
            <p className="info-text">{Data?.il_hakemi_kurs_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>İl Hakemi Belge Tarihi</p>
          <div className="card-text">
            <p className="info-text">{Data?.il_hakemi_belge_tarihi}</p>
          </div>
        </div>
        <div className="info-card">
          <p>Belge NO</p>
          <div className="card-text">
            <p className="info-text">{Data?.belge_no}</p>
          </div>
        </div>
      </div>
        <button onClick={back} className='button' style={{width:"100%"}}>Geri Dön</button>
    </div>
  )
}
