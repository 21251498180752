import { Table,Input } from 'antd';
import React,{useState,useEffect} from 'react'
import { AllReferee } from '../../../api/AllReferee';
import AddCourseModal from '../../../components/AddCourseModal';
import { columns } from '../../../data/Columns';

export default function AddCourse() {
    const [originalData, setOriginalData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]); 
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [editModal, setEditModal] = useState(false)

    useEffect(() => {
        AllReferee().then((response) => {
            const reversedData = response?.data.slice().reverse(); 
            setOriginalData(reversedData); 
            setFilteredData(reversedData); 
        });
      }, [editModal]);


  useEffect(() => {
    const filtered = originalData.filter(item =>
      item?.ad_soyad?.toLowerCase().includes(searchTerm?.toLowerCase()) 
    );
    setFilteredData(filtered);
  }, [searchTerm, originalData]);

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setEditModal(true)
  };


  return (
    <div className="admin-home-container">
    <div
      className="admin-cards"
      style={{ justifyContent: "space-between", marginBottom: "2rem" }}
    >
      <div style={{ backgroundColor: "#037D00" }} className="admin-card">
        <i className="fa-solid fa-users fa-3x"></i>
        <div className="admin-card-text">
          <p className="count">
            <strong>{originalData?.length}</strong>
          </p>
          <p>Toplam Hakem Sayısı</p>
        </div>
      </div>
    </div>
    <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Hakem Adına Göre Ara"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredData} 
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record), 
          };
        }}
        pagination={{ showSizeChanger: false, position: ["bottomCenter"] }}
    />
    <AddCourseModal modal={editModal} close={() => setEditModal(false)} data={selectedRowData}/>
  </div>
  )
}
