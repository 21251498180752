import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { AllReferee } from "../../../api/AllReferee";
import * as XLSX from 'xlsx';
import AddRefereeModal from "../../../components/AddRefereeModal";
import { columns } from '../../../data/Columns';
import DetailModal from "../../../components/DetailModal";


export default function HakemEkle() {
    const [originalData, setOriginalData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]); 
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [addReferee, setAddReferee] = useState(false)
    const [detailOpen,setDetailOpen] = useState(false)

    useEffect(() => {
        AllReferee().then((response) => {
          const reversedData = response?.data.slice().reverse(); 
            setOriginalData(reversedData); 
            setFilteredData(reversedData); 
        });
      }, [addReferee]);


  useEffect(() => {
    const filtered = originalData.filter(item =>
      item?.ad_soyad?.toLowerCase().includes(searchTerm?.toLowerCase()) 
    );
    setFilteredData(filtered);
  }, [searchTerm, originalData]);

  
  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setDetailOpen(true)
  };

  const exportToExcel = () => {
    const columnHeaders = columns.map(column => column.title);
  
    const dataForExport = filteredData.map(item => {
      return columns.reduce((newItem, column) => {
        newItem[column.title] = item[column.dataIndex];
        return newItem;
      }, {});
    });
  
    const worksheetData = [columnHeaders, ...dataForExport.map(item => {
      return columnHeaders.map(header => item[header]);
    })];
  
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hakemler");
  
    const exportFileName = "Hakemler.xlsx";
    XLSX.writeFile(wb, exportFileName);
  };
  
const modalOpen = () => {setAddReferee(true)}
  return (
    <div className="admin-home-container">
      <div
        className="admin-cards"
        style={{ justifyContent: "space-between", marginBottom: "2rem" }}
      >
        <div style={{ backgroundColor: "#037D00" }} className="admin-card">
          <i className="fa-solid fa-users fa-3x"></i>
          <div className="admin-card-text">
            <p className="count">
              <strong>{originalData?.length}</strong>
            </p>
            <p>Toplam Hakem Sayısı</p>
          </div>
        </div>
        <div className="panel-buttons">
            <button className="excel-button" onClick={exportToExcel}>Excel Olarak Aktar</button>
            <button className="excel-button" onClick={modalOpen}>Hakem Ekle</button>
        </div>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Hakem Adına Göre Ara"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredData} 
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record), 
          };
        }}
        pagination={{ showSizeChanger: false, position: ["bottomCenter"] }}
      />
      <AddRefereeModal modal={addReferee} close={()=>setAddReferee(false)}/>
      <DetailModal modal={detailOpen} close={()=>setDetailOpen(false)} data={selectedRowData}/>
    </div>
  );
}
