import { Modal } from "antd";
import React, { useState } from "react";
import "./EditModal.css";
import Edit from "./Edit";
import { DeleteReferee } from "../../api/DelteReferee";

export default function EditRefereeModal({ modal, close, data }) {
  const [edit, setEdit] = useState(false);

  const handleEditClick = () => { setEdit(true); };

  const deleteReferee = () => {
    DeleteReferee(data?.id).then((ress)=>{
        alert("Hakem Başarı ile silind")
        close()
    })
  }
  const handleClose = () => {
    close(); 
    setEdit(false); 
  };

 
  return (
    <Modal
      title="Hakem Sil veya Düzenle"
      visible={modal}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
    >
      <>
        <div className="edit-modal-container">
          <p>Yapmak İstediğiniz İşlem</p>
          <div className="edit-modal-buttons">
            <button className="btn btn-danger" onClick={deleteReferee}>Hakemi Sil</button>
            <button className="btn btn-success" onClick={handleEditClick}>Hakemi Düzenle</button>
          </div>
        </div>
        {edit && <Edit data={data} close={close}/>}
      </>
    </Modal>
  );
}
