export const columns = [
  {
    title: "T.C. Kimlik No",
    dataIndex: "tc_kimlik_no",
    key: "tc_kimlik_no",
  },
  {
    title: "Ad Soyad",
    dataIndex: "ad_soyad",
    key: "ad_soyad",
  },
  {
    title: "Kurs Adı",
    dataIndex: "kurs_adi",
    key: "kurs_adi",
    render: kurs_adi => kurs_adi?.kurslar[kurs_adi.kurslar.length - 1],
  },
  {
    title: "Telefon No",
    dataIndex: "tel_no",
    key: "tel_no",
  },
  {
    title: "Belge Numarası",
    dataIndex: "belge_no",
    key: "belge_no",
  },
  {
    title: "Aday Belge Tarihi",
    dataIndex: "aday_hakem_belge_tarihi",
    key: "aday_hakem_belge_tarihi",
  },
];
